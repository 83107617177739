<template>
    <v-app-bar
        app
        color="blue darken-4"
    >
        <div class="">

            <div v-if="mobile">
                <p
                    class="caption mt-3"
                    style="color: white;"
                >Ibertrack
                </p>

            </div>

            <div v-else>

                <router-link to="/Home"
                class="no-decoration"
                >

                <div class="d-flex flex-row mb-0">
                    <v-img
                        src="../assets/incyte_w.png"
                        contain
                        class="my-0 ml-0"
                        width="60"
                        height="45"/>

                    <v-img
                        src="../assets/line.png"
                        contain
                        class="my-0 ml-3"
                        width="1.2"
                        height="45"/>

                    <v-card-title
                        class="pt-0 pb-0 mt-0"
                        style="color: white;"
                    > Ibertrack
                    </v-card-title>


                </div>

                </router-link>

            </div>


        </div>

        <v-progress-circular
            indeterminate
            color="white"
            v-if="loading"
        ></v-progress-circular>

        <v-spacer></v-spacer>


        <v-menu
            rounded="b-xl"
            open-on-hover
            offset-y
            close-delay="320"

        >
            <template v-slot:activator="{ attrs, on }">
                <v-btn
                    fab
                    small
                    color="grey lighten-2"
                    class="transparent"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon color="blue darken-4">mdi-format-list-bulleted-square</v-icon>

                </v-btn>
            </template>


            <v-list
            :key="this.componentKey"
            >

                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    link
                    @click="pushRoute(item)"
                >


                    <v-list-item-title v-text="item.title">
                    </v-list-item-title>

                </v-list-item>


            </v-list>
        </v-menu>


    </v-app-bar>


</template>
<script>

import {mapActions, mapState} from "vuex";
import router from "../router";

export default {
    name: 'App',

    data: () => ({
        // ['Treatments tracker', 'Hospitals', 'Data analysis', 'Sign out']
        btns: [
            ['Custom', 'b-xl'],
        ],
        colors: ['white'],
        items_a: [
            {title: "Home", link: "Home", color: "blue darken-4"},
            {title: "Treatments tracker", link: "TreatmentTracker", color: "blue darken-4"},
            {title: "Hospitals", link: "Hospitals", color: "blue darken-4"},
            {title: "Data analysis", link: "Data", color: "blue darken-4"},
            {title: "Reports", link: "Reports", color: "red lighten-4"},
            {title: "Sign out", link: "Login", color: "red lighten-4"}
        ],
        items_b: [
            {title: "Home", link: "Home", color: "blue darken-4"},
            {title: "Data analysis", link: "Data", color: "blue darken-4"},
            {title: "Reports", link: "Reports", color: "red lighten-4"},
            {title: "Sign out", link: "Login", color: "red lighten-4"}
        ],
        componentKey: 1


    }), computed: {
        ...mapState(['loading', 'user']),

        items() {

            if (this.user.ut.ut === 3) {
                return this.items_b
            }

            return this.items_a
        },


        mobile() {
            if (this.$vuetify.breakpoint.width < 500) {
                return true
            } else {
                return false
            }

        },

    },


    methods: {
        ...
            mapActions(['signOutUser', 'getUserName']),

        pushRoute(route) {
            if (route.link !== 'Login') {
                router.push(`/${route.link}`).catch(err => {
                })
            } else {
                this.signOutUser()
            }
        },

        forceRerender() {
            setTimeout(()=>{
                this.componentKey += 1
            },1000)


        }
    }

}
;
</script>

<style scoped>
.v-list-item:hover {
    background-color: #E0E0E0;

}

 .row-pointer {
     cursor: pointer;
 }
.no-decoration {
    text-decoration: none;
}

</style>
