import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import JsonCSV from 'vue-json-to-csv'
import {auth, db, database} from "./firebase";
import Vuelidate from "vuelidate";


Vue.config.productionTip = false

auth.onAuthStateChanged(user => {
    if (user) {


        user.getIdTokenResult().then(u_data => {
            const checkUser = {
                email: user.email,
                uid: user.uid,
                ut: u_data.claims
            }
            store.dispatch('detectUser', checkUser)
        })


        const userStatusDatabaseRef = database.ref("/users/" + user.uid);


        const updateStatus = (isOnline) => {
            const currentTime = Date.now();
            const statusObject = {
                status: isOnline ? "online" : "offline",
                email: user.email,
                uid: user.uid,
                lastChanged: currentTime,
            };
            userStatusDatabaseRef.set(statusObject);
            return statusObject;
        };

        // Primera vez
        updateStatus(true);

        // Al desconectar
        const offlineStatusObject = updateStatus(false);
        userStatusDatabaseRef.onDisconnect().set(offlineStatusObject);

        // Cuando la pestaña cambia de visibilidad
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                updateStatus(true);
            } else {
                updateStatus(false);
            }
        });






    } else {
        store.dispatch('detectUser', null)
        window.localStorage.clear();

    }
    new Vue({
        router,
        store,
        vuetify,
        JsonCSV,
        Vuelidate,
        auth,

        render: h => h(App)
    }).$mount('#app')

})


