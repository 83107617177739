import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from '../firebase'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {requiresAuth: true, requiresKam: false}
    },

    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {requiresAuth: true, requiresKam: false}
    },


    {
        path: '/treatmenttracker',
        name: 'TreatmentTracker',
        component: () => import(/* webpackChunkName: "TreatmentTracker" */ '../views/TreatmentTracker.vue'),
        meta: {requiresAuth: true, requiresKam: false}
    },


    {
        path: '/reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "Reports" */ '../views/Reports.vue'),
        meta: {requiresAuth: true, requiresKam: false}
    },


    {
        path: '/data',
        name: 'Data',
        component: () => import(/* webpackChunkName: "data" */ '../views/Data.vue'),
        meta: {requiresAuth: true, requiresKam: false}
    },

    {
        path: '/hospitals',
        name: 'Hospitals',
        component: () => import(/* webpackChunkName: "hospitals" */
            '../views/Hospitals.vue'),
        meta: {requiresAuth: true, requiresKam: true}
    },

    {
        path: '/Login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        meta: {requiresAuth: false, requiresKam: false}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {


    /*User 1 2*/
    if (to.matched.some(record => ((record.meta.requiresAuth) && (record.meta.requiresKam)))) {

        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!auth.currentUser) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })

        } else if (auth.currentUser.displayName === '3') {
            next({
                path: '/home',
                query: {redirect: to.fullPath}
            })
        } else {
            if (to.name === 'Login') {
                next({
                    path: '/home',
                    query: {redirect: to.fullPath}
                })
            } else {
                next()
            }

        }
    } else if (to.matched.some(record => ((record.meta.requiresAuth) && (!record.meta.requiresKam)))) {

        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!auth.currentUser) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })


        } else {


            if (to.name === 'Login') {
                next({
                    path: '/home',
                    query: {redirect: to.fullPath}
                })
            } else {
                next()
            }

        }


    } else {

        if (auth.currentUser) {
            if (to.name === 'Login') {
                next({
                    path: '/home',
                    query: {redirect: to.fullPath}
                })
            } else {
                next()
            }

        } else {
            next()
        }

        next()
    }
})


export default router
