<template>

    <v-app class="" :style="[userCheck ? background_app : background]">
      <Navbar class="" v-if="userCheck"></Navbar>
        <v-main class="mt-16 pt-1 mr-3 ml-3">
        <router-view/>
      </v-main>
    </v-app>

</template>

<script>

import Navbar from "./components/Navbar";

import {mapGetters} from "vuex";


export default {
    name: 'App',
    components: {
        Navbar
    },

    data: () => ({

        background: {

            backgroundImage: `url(${require('@/assets/background_network.png')})`,
            width: "100%",

            //Ocupamos el 100% de la pantalla sin estirar la foto
            backgroundSize: "cover",



        },

        background_app: {backgroundColor: "#dcdcdc"}

    }),

    computed: {

        ...mapGetters(['userCheck'])

    }


};
</script>
