import firebase from "firebase/app"
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/database'




// const firebaseConfig = {
//     apiKey: "AIzaSyA-AuH-YcU72iEy505Mw8RcEl5LsHsot4I",
//     authDomain: "pre-treatment-tracker.firebaseapp.com",
//     databaseURL: "https://pre-treatment-tracker-default-rtdb.europe-west1.firebasedatabase.app",
//     projectId: "pre-treatment-tracker",
//     storageBucket: "pre-treatment-tracker.appspot.com",
//     messagingSenderId: "911395895866",
//     appId: "1:911395895866:web:82b4a48dc887971289d303"
// };



const firebaseConfig = {
    apiKey: "AIzaSyBHpYHIivu2cnPm4Xh8vVR5uulIfdAcUTU",
    authDomain: "iber-track.firebaseapp.com",
    databaseURL: "https://iber-track-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "iber-track",
    storageBucket: "iber-track.appspot.com",
    messagingSenderId: "146850569133",
    appId: "1:146850569133:web:8555be59d4181163f75f24",
    measurementId: "G-S53JDYYTGD"
};




// Initialize Firebase
firebase.initializeApp(firebaseConfig);



const database = firebase.database()
const db = firebase.firestore()
const auth = firebase.auth()
const persistence = firebase.auth
export { db, auth, persistence, database }
